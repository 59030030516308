import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  CANCEL_THIRD_PARTY_PROMOTION:                   type('[Engagement] Cancel Third Party Promotion'),
  CANCEL_THIRD_PARTY_PROMOTION_ERROR:             type('[Engagement] Cancel Third Party Promotion Error'),
  CANCEL_THIRD_PARTY_PROMOTION_SUCCESS:           type('[Engagement] Cancel Third Party Promotion Success'),
  CCE_UPDATE_MODAL_SHOWN:                         type('[Engagement] CCE update modal shown'),
  CREATE_AUTOMATION_FAILURE:                      type('[Engagement] Create automation failure'),
  CREATE_AUTOMATION_SUCCESS:                      type('[Engagement] Create automation success'),
  EDIT_AUTOMATION:                                type('[Engagement] Edit automation'),
  EDIT_AUTOMATION_FAILURE:                        type('[Engagement] Edit automation failure'),
  EDIT_AUTOMATION_SUCCESS:                        type('[Engagement] Edit automation success'),
  FETCH_COMMUNITY_CARE_DATE_RESTRICTION_SUCCESS:  type('[Engagement] Fetch community care date restriction success'),
  FETCH_ENGAGEMENT_DETAILS_SUCCESS:               type('[Engagement] Fetch engagement details success'),
  FETCH_INTERNAL_PROMO_DATE_RESTRICTION_SUCCESS:  type('[Engagement] Fetch internal promotions, bpp and fic date restriction success'),
  FETCH_THIRD_PARTY_DATE_RESTRICTION_SUCCESS:     type('[Engagement] Fetch third party date restriction success'),
  GO_TO_COMMUNITY_OVERLAPPING_ENGAGEMENT:         type('[Engagement] Go To Community Care Overlapping Engagement'),
  GO_TO_DISCOVER_PAGE:                            type('[Engagement] Go To Discover Page'),
  OPEN_CANCELLATION_DIALOG:                       type('[Engagement] Open Cancellation Dialog'),
  RECEIVED_ENGAGEMENT_FAILED:                     type('[Engagement] Received failed'),
  SELECT_ENGAGEMENT_DATES:                        type('[Engagement] Select Engagement Dates'),
  SELECT_NEW_REWARD:                              type('[Engagement] Select new reward'),
  SELECT_OVERLAPPING_ENGAGEMENT_TYPE:             type('[Engagement] Select Overlapping Engagement Type'),
  SEND_COMMUNITY_CARE_EVENT_SUCCESS:              type('[Engagement] Send communityEvent success'),
  SEND_EMAIL_SUCCESS:                             type('[Engagement] Send email success'),
  SEND_ENGAGEMENT:                                type('[Engagement] Send Engagement'),
  SEND_FAILURE:                                   type('[Engagement] Send engagement failure'),
  SEND_FREE_IN_CART_FAILURE:                      type('[Engagement] Send free in cart failure'),
  SEND_FREE_IN_CART_SUCCESS:                      type('[Engagement] Send free in cart success'),
  SEND_PROMOTION_SUCCESS:                         type('[Engagement] Send promotion success'),
  SEND_REWARD_SUCCESS:                            type('[Engagement] Send reward success'),
  SEND_THIRD_PARTY_PROMOTION_FAILURE:             type('[Engagement] Send third party failure'),
  SEND_THIRD_PARTY_PROMOTION_SUCCESS:             type('[Engagement] Send third party promotion success'),
  START_ONE_PAGE_FLOW:                            type('[Engagement] Start one page flow'),
  STOP_AUTOMATION:                                type('[Engagement] Stop automation'),
  STOP_AUTOMATION_FAILURE:                        type('[Engagement] Stop automation failure'),
  STOP_AUTOMATION_SUCCESS:                        type('[Engagement] Stop automation success'),
  STOP_COMMUNITY_CARE_EVENT:                      type('[Engagement] Stop community care event'),
  STOP_COMMUNITY_CARE_EVENT_FAILURE:              type('[Engagement] Stop community care event failure'),
  STOP_COMMUNITY_CARE_EVENT_SUCCESS:              type('[Engagement] Stop community care event success'),
  STOP_FREE_IN_CART:                              type('[Engagement] Stop free in cart'),
  STOP_FREE_IN_CART_FAILURE:                      type('[Engagement] Stop free in cart failure'),
  STOP_FREE_IN_CART_SUCCESS:                      type('[Engagement] Stop free in cart success'),
  UPDATE_ENGAGEMENT:                              type('[Engagement] Update engagement'),
  UPDATE_ENGAGEMENT_CUSTOMER_TYPES:               type('[Engagement] Update engagement customer types'),
  UPDATE_ENGAGEMENT_DATES:                        type('[Engagement] Update engagement dates'),
  UPDATE_ENGAGEMENT_MULTI_SELECT_DESTINATIONS:    type('[Engagement] Update engagement multi select destinations'),
  UPDATE_ENGAGEMENT_PLATFORMS:                    type('[Engagement] Update engagement platforms'),
  UPDATE_ENGAGEMENT_REPEAT_PROMOTION:             type('[Engagement] Update Engagement Repeat Promotion'),
  UPDATE_ENGAGEMENT_START_TIME:                   type('[Engagement] Update Engagement Start Time'),
  UPDATE_IS_AUTOMATED:                            type('[Engagement] Update is automated'),
  UPDATE_TIME_OF_DAY:                             type('[Engagement] Update Engagement Time Of Day')
};

export class CancelThirdPartyPromotion implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION;
  constructor(public payload: any = '') {}
}

export class CancelThirdPartyPromotionError implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_ERROR;
  constructor(public payload: any = '') {}
}

export class CancelThirdPartyPromotionSuccess implements Action {
  public type = ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS;
  constructor(public payload: any = '') {}
}

export class CCEUpdateModalShown implements Action {
  public type = ActionTypes.CCE_UPDATE_MODAL_SHOWN;
  constructor(public payload: any = '') { }
}

export class CreateAutomationFailure implements Action {
  public type = ActionTypes.CREATE_AUTOMATION_FAILURE;
  constructor(public payload: any = '') { }
}

export class CreateAutomationSuccess implements Action {
  public type = ActionTypes.CREATE_AUTOMATION_SUCCESS;
  constructor(public payload: any = '') { }
}

export class EditAutomation implements Action {
  public type = ActionTypes.EDIT_AUTOMATION;
  constructor(public payload: any = '') { }
}

export class EditAutomationFailure implements Action {
  public type = ActionTypes.EDIT_AUTOMATION_FAILURE;
  constructor(public payload: any = '') { }
}

export class EditAutomationSuccess implements Action {
  public type = ActionTypes.EDIT_AUTOMATION_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchCommunityCareDateRestrictionSuccess implements Action {
  public type = ActionTypes.FETCH_COMMUNITY_CARE_DATE_RESTRICTION_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchEngagementDetailsSuccess implements Action {
  public type = ActionTypes.FETCH_ENGAGEMENT_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchInternalPromoDateRestrictionSuccess implements Action {
  public type = ActionTypes.FETCH_INTERNAL_PROMO_DATE_RESTRICTION_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchThirdPartyDateRestrictionSuccess implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_DATE_RESTRICTION_SUCCESS;
  constructor(public payload: any) { }
}

export class GoToCommunityCareOverLappingEngagement implements Action {
  public type = ActionTypes.GO_TO_COMMUNITY_OVERLAPPING_ENGAGEMENT;
  constructor(public payload: any = '') { }
}

export class GoToDiscoverPage implements Action {
  public type = ActionTypes.GO_TO_DISCOVER_PAGE;
  constructor(public payload: any = '') { }
}

export class OpenCancellationDialog implements Action {
  public type = ActionTypes.OPEN_CANCELLATION_DIALOG;
  constructor(public payload: any) { }
}

export class ReceivedEngagementFailed implements Action {
  public type = ActionTypes.RECEIVED_ENGAGEMENT_FAILED;
  constructor(public payload: any) {}
}

export class SelectEngagementDates implements Action {
  public type = ActionTypes.SELECT_ENGAGEMENT_DATES;
  constructor(public payload: any = '') { }
}

export class SelectNewReward implements Action {
  public type = ActionTypes.SELECT_NEW_REWARD;
  constructor(public payload: any) { }
}

export class SelectOverLappingEngagementType implements Action {
  public type = ActionTypes.SELECT_OVERLAPPING_ENGAGEMENT_TYPE;
  constructor(public payload: any = '') { }
}

export class SendCommunityCareEventSuccess implements Action {
  public type = ActionTypes.SEND_COMMUNITY_CARE_EVENT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendEmailSuccess implements Action {
  public type = ActionTypes.SEND_EMAIL_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendEngagement implements Action {
  public type = ActionTypes.SEND_ENGAGEMENT;
  constructor(public payload: any = '') { }
}

export class SendFailure implements Action {
  public type = ActionTypes.SEND_FAILURE;
  constructor(public payload: any = '') { }
}

export class SendFreeInCartFailure implements Action {
  public type = ActionTypes.SEND_FREE_IN_CART_FAILURE;
  constructor(public payload: any) { }
}

export class SendFreeInCartSuccess implements Action {
  public type = ActionTypes.SEND_FREE_IN_CART_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendPromotionSuccess implements Action {
  public type = ActionTypes.SEND_PROMOTION_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendRewardSuccess implements Action {
  public type = ActionTypes.SEND_REWARD_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SendThirdPartyPromotionFailure implements Action {
  public type = ActionTypes.SEND_THIRD_PARTY_PROMOTION_FAILURE;
  constructor(public payload: any) { }
}

export class SendThirdPartyPromotionSuccess implements Action {
  public type = ActionTypes.SEND_THIRD_PARTY_PROMOTION_SUCCESS;
  constructor(public payload: any = '') { }
}

export class StartOnePageFlow implements Action {
  public type = ActionTypes.START_ONE_PAGE_FLOW;
  constructor(public payload: any) { }
}

export class StopAutomation implements Action {
  public type = ActionTypes.STOP_AUTOMATION;
  constructor(public payload: any) { }
}

export class StopAutomationFailure implements Action {
  public type = ActionTypes.STOP_AUTOMATION_FAILURE;
  constructor(public payload: any) { }
}

export class StopAutomationSuccess implements Action {
  public type = ActionTypes.STOP_AUTOMATION_SUCCESS;
  constructor(public payload: any) { }
}

export class StopCommunityCareEvent implements Action {
  public type = ActionTypes.STOP_COMMUNITY_CARE_EVENT;
  constructor(public payload: any) { }
}

export class StopCommunityCareEventFailure implements Action {
  public type = ActionTypes.STOP_COMMUNITY_CARE_EVENT_FAILURE;
  constructor(public payload: any) { }
}

export class StopCommunityCareEventSuccess implements Action {
  public type = ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS;
  constructor(public payload: any) { }
}

export class StopFreeInCart implements Action {
  public type = ActionTypes.STOP_FREE_IN_CART;
  constructor(public payload: any) { }
}

export class StopFreeInCartFailure implements Action {
  public type = ActionTypes.STOP_FREE_IN_CART_FAILURE;
  constructor(public payload: any) { }
}

export class StopFreeInCartSuccess implements Action {
  public type = ActionTypes.STOP_FREE_IN_CART_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateEngagement implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT;
  constructor(public payload: any) { }
}

export class UpdateEngagementCustomerTypes implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_CUSTOMER_TYPES;
  constructor(public payload: any) { }
}

export class UpdateEngagementDates implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_DATES;
  constructor(public payload: any) { }
}

export class UpdateEngagementMultiSelectDestinations implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_MULTI_SELECT_DESTINATIONS;
  constructor(public payload: any) { }
}

export class UpdateEngagementPlatforms implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_PLATFORMS;
  constructor(public payload: any) { }
}

export class UpdateEngagementRepeatPromotion implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_REPEAT_PROMOTION;
  constructor(public payload: any) { }
}

export class UpdateEngagementStartTime implements Action {
  public type = ActionTypes.UPDATE_ENGAGEMENT_START_TIME;
  constructor(public payload: any = '') { }
}

export class UpdateIsAutomated implements Action {
  public type = ActionTypes.UPDATE_IS_AUTOMATED;
  constructor(public payload: any) { }
}

export class UpdateTimeOfDay implements Action {
  public type = ActionTypes.UPDATE_TIME_OF_DAY;
  constructor(public payload: any = '') { }
}

export type Actions
  = CancelThirdPartyPromotion
  | CancelThirdPartyPromotionError
  | CancelThirdPartyPromotionSuccess
  | CCEUpdateModalShown
  | CreateAutomationFailure
  | CreateAutomationSuccess
  | EditAutomation
  | EditAutomationFailure
  | EditAutomationSuccess
  | FetchCommunityCareDateRestrictionSuccess
  | FetchEngagementDetailsSuccess
  | FetchInternalPromoDateRestrictionSuccess
  | FetchThirdPartyDateRestrictionSuccess
  | GoToCommunityCareOverLappingEngagement
  | GoToDiscoverPage
  | OpenCancellationDialog
  | ReceivedEngagementFailed
  | SelectEngagementDates
  | SelectNewReward
  | SelectOverLappingEngagementType
  | SendCommunityCareEventSuccess
  | SendEmailSuccess
  | SendEngagement
  | SendFailure
  | SendFreeInCartFailure
  | SendFreeInCartSuccess
  | SendPromotionSuccess
  | SendRewardSuccess
  | SendThirdPartyPromotionFailure
  | SendThirdPartyPromotionSuccess
  | StartOnePageFlow
  | StopAutomation
  | StopAutomationFailure
  | StopAutomationSuccess
  | StopCommunityCareEvent
  | StopCommunityCareEventFailure
  | StopCommunityCareEventSuccess
  | StopFreeInCart
  | StopFreeInCartFailure
  | StopFreeInCartSuccess
  | UpdateEngagement
  | UpdateEngagementCustomerTypes
  | UpdateEngagementDates
  | UpdateEngagementMultiSelectDestinations
  | UpdateEngagementPlatforms
  | UpdateEngagementRepeatPromotion
  | UpdateEngagementStartTime
  | UpdateIsAutomated
  | UpdateTimeOfDay;
