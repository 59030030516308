import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedConstants } from '../shared.constants';
import { EngageConstants } from '../../engage/engage.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Store } from '@ngrx/store';
import * as engage from '../../actions/engage';
import * as fromRoot from '../../reducers';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-community-care-details',
  templateUrl: './community-care-details.component.html',
  styleUrls: ['./community-care-details.component.scss']
})
export class CommunityCareDetailsComponent implements OnInit {
  @Input() tile;
  @Input() optInButtons;
  @Input() isMobile;
  @Input() eventId;
  @Input() campaignName;
  @Input() organizations: any[];
  @Input() dateAndTimeObject;
  @Input() selectedLocation;
  @Input() selectedOptIn;
  @Input() status;

  @Input() isReview;
  @Input() disabledDays;
  @Input() disabledTimes;
  @Input() showCustomers;
  @Input() filteredRecipients;
  @Input() totalCommunityCareEventSales;

  @Output() onAnyDateChange = new EventEmitter<any>();
  @Output() onAnyEngagementChange = new EventEmitter<any>();
  @Output() onStartTimeChanged = new EventEmitter<any>();
  @Output() onSearchRecipients = new EventEmitter<string>();
  @Output() onGoBack = new EventEmitter();
  @Output() onGoToOverlappingEvents = new EventEmitter<any>();
  @Output() onGoToPreview = new EventEmitter<any>();
  @Output() onSendEvent = new EventEmitter<any>();

  public editForm: FormGroup;
  public campaignNameFormControl: FormControl;
  public startDateFormControl: FormControl;
  public endDateFormControl: FormControl;
  public endTimeFormControl: FormControl;
  public startTimeFormControl: FormControl;
  private currentOrganizations: any[] = [];
  public showOrganizationCodeBool: boolean;
  public readMoreExpanded: boolean = false;
  public noCustomersFoundMessage: any;
  public searchByCustomerNameMessage: any;
  public tableColumns: any;
  public communityCareCustomerNote: any;
  public recipientsSearchTerm;
  public getEngageDisableIndividualRowAttribute: any;
  public heartIcon = SharedConstants.heartIcon;
  public operatingStandardUrl = SharedConstants.operatingStandardUrl;
  public givingStrategyUrl = SharedConstants.givingStrategyUrl;

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>, public dialog: MatDialog) {
    this.editForm = this.fb.group({
      organizations: this.fb.array([this.addOrganizations('')])
    });
  }

  ngOnInit(): void {
    this.setDateTimeValidation();
    this.showOrganizationCodeBool = this.selectedOptIn === SharedConstants.organizationCode;
    this.campaignNameFormControl = new FormControl(
      this.campaignName,
      [Validators.pattern('^(?!\\s*$).+'), Validators.required]
    );
    this.editForm.addControl('campaignName', this.campaignNameFormControl);
    if (this.organizations.length > 0) {
      this.organizationArray.clear();
      this.organizations.forEach((organization) => {
        this.addOrganization(organization);
      })
    }
    this.noCustomersFoundMessage = SharedConstants.noCustomersFoundMessage;
    this.searchByCustomerNameMessage= SharedConstants.searchByCustomerNameMessage;
    this.tableColumns = SharedConstants.communityCareCustomerColumns;
    this.communityCareCustomerNote = SharedConstants.communityCareCustomerNote;
    this.recipientsSearchTerm = '';
    this.getEngageDisableIndividualRowAttribute = SharedConstants.disableIndividualRowAttribute;

  }

  public setDateTimeValidation() {
    this.startTimeFormControl = new FormControl(this.dateAndTimeObject.startTime, [Validators.required]);
    this.editForm.addControl('startTime', this.startTimeFormControl);
    this.endTimeFormControl = new FormControl(this.dateAndTimeObject.endTime, [Validators.required]);
    this.editForm.addControl('endTime', this.endTimeFormControl);
  }

  get organizationArray(): FormArray {
    return <FormArray>this.editForm.get('organizations');
  }

  private addOrganizations(organization): FormGroup {
    return this.fb.group({
      organizationCode: organization.organizationCode ? organization.organizationCode : '',
      organizationName: new FormControl(organization.organizationName ? organization.organizationName : '',
        [Validators.pattern('^(?!\\s*$).+'), Validators.required]),
    });
  }

  public addOrganization(organization) {
    this.organizationArray.push(this.addOrganizations(organization));
    this.setValidationOrganizationCode(this.showOrganizationCodeBool);
  }



  public checkDuplicate(event, field: FormControl) {
    if (this.organizations[0].organizationName === this.organizations[1].organizationName) {
      field.markAsTouched();
      field.setValidators((f) => <any>{ duplicateName: true });
      field.updateValueAndValidity();
    } else {
      field.clearValidators();
      field.setValidators([Validators.required]);
      field.updateValueAndValidity();
      }
    }


  public setValidationOrganizationCode(showOrganizationCodeBool) {
    (this.editForm.get('organizations') as FormArray).controls.forEach((control, i) => {
      if (showOrganizationCodeBool) {
        control.get('organizationCode').setValidators([Validators.minLength(5), Validators.pattern('^[a-zA-Z0-9]+$'), Validators.required]);
        control.get('organizationCode').updateValueAndValidity();
      } else {
        control.get('organizationCode').clearValidators();
        control.get('organizationCode').updateValueAndValidity();
      }
    });
  }

  public removeOrganization(index) {
    this.organizationArray.removeAt(index);
  }

  public onOrganizationNameInputChange(event, index) {
    let organizationName = this.organizationArray.controls[index].value.organizationName;
    let organizationCode = this.organizationArray.controls[index].value.organizationCode;

    this.currentOrganizations[index] = {
      organizationName: organizationName,
      organizationCode: organizationCode === '' ? null : organizationCode
    };
    this.onAnyEngagementChange.emit({ organizations: this.currentOrganizations });
  }

  public onCampaignNameChange(evt) {
    this.onAnyEngagementChange.emit({ campaignName: evt.target.value })
  }

  public updateOptIn(event) {
    if (event === 'noCode') {
      this.editForm.get('organizations')['controls'][0].controls.organizationCode.value = '';
      if (this.editForm.get('organizations')['controls'][1]) {
        this.editForm.get('organizations')['controls'][1].controls.organizationCode.value = '';
      }
      this.organizationArray.controls.forEach((organization, index) => {
        organization.value.organizationCode = '';
      });
    }

    this.showOrganizationCodeBool = event === SharedConstants.organizationCode;

    // call validation method for Organization add
    this.setValidationOrganizationCode(this.showOrganizationCodeBool);
    this.onAnyEngagementChange.emit({ selectedOptIn: event })
  }

  public endDateChanged(endDate) {
    this.onAnyDateChange.emit( { startDate: this.dateAndTimeObject.startDate, endDate: endDate });
  }

  public startDateChanged(startDate) {
    this.onAnyDateChange.emit( { startDate: startDate, endDate: this.dateAndTimeObject.endDate });
  }

  public endTimeChanged(endTime) {
    this.onAnyEngagementChange.emit({ endTime: endTime });
  }

  // Only display the total sales if there are more than 1 organization
  public getOrganizationTitle(organization, organizations): string {
    let title = organization.organizationName;
    if (organizations.length > 1) {
      title += ': $' + organization.totalSales;
    }
    return title;
  }

  public getNumberOfPages(totalElements) {
    return Math.ceil(totalElements / 5);
  }

  public searchRecipients(isCleared) {
    if (isCleared) {
      this.recipientsSearchTerm = '';
    }
    this.onSearchRecipients.emit(this.recipientsSearchTerm);
  }

  public validateAndSend(isPreview) {
    this.editForm.markAllAsTouched();
    if (this.editForm.valid) {
      if (isPreview) {
        this.onGoToPreview.emit();
      } else {
        this.onSendEvent.emit();
      }
    }
  }

  public expandReadMore() {
    this.readMoreExpanded = true;
  }

  public collapseReadMore() {
    this.readMoreExpanded = false;
  }

  public isEventPastOrCancelled(): boolean {
    return (this.dateAndTimeObject.endDate).isBefore(moment()) || this.status === EngageConstants.cancelledStatus;
  }

  public cancelScheduledEvent() {
    let config = new MatDialogConfig();
    config.data = {
      displayText: 'Are you sure you want to cancel this event?',
      confirmText: 'Yes, Cancel Event',
      cancelText: 'No, Keep Event'
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new engage.CancelCommunityCareEventFromCalendar({ eventId: this.eventId }));
      }
    });
  }
}
