import * as engagement from '../actions/engagement';
import * as user from '../actions/user'
import * as moment from 'moment-timezone';
import { clone, find, propEq, filter } from 'ramda';
import { DiscoverConstants } from '../discover/discover.constants';
import { EngageConstants } from '../engage/engage.constants';
import { SharedConstants } from '../shared/shared.constants';

export interface State {
  allCustomerTypes: any[];
  allDaysBlocked: boolean;
  areDatesWithinSevenDays: boolean;
  availableCustomerTypes: any[];
  campaignName: string;
  cceModalAlreadyShown: boolean;
  communityCareDateRestrictions: any[];
  count: number;
  currencyCode: any;
  defaultMessageForApp: string;
  destinationOptions: any[];
  disabledDays: string[];
  disabledTimes: any;
  disabledTimesMap: any;
  duplicateRecipients: any[];
  email: any;
  endDate: any;
  endDateRestriction: any;
  endTime: string;
  endTimeEndRestriction: string;
  endTimeRestriction: string;
  engagementAction?: string;
  engagementMode?: string;
  engagementType?: string;
  error?: string;
  failedPlatforms: string;
  flow: any[];
  flowId?: string;
  freeItemInCartCFADeliveryEnabled: boolean;
  freeItemInCartDoorDashEnabled: boolean;
  freeItemInCartFlags: any;
  freeItemInCartPickupEnabled: boolean;
  includeActiveTreats: boolean;
  invalidated: boolean;
  loading: boolean;
  maxDaysAllowed: number;
  menuItems: any[];
  message: string;
  offerRecipients: any[];
  optInOptions: any;
  organizations: any[];
  overlappingEngagementType: string;
  platforms: any[];
  previewHtml: string;
  primaryText: string;
  pushMessageText: string;
  pushMessageTitle: string;
  repeatOptions: any;
  repeatPromotionSubId: any;
  selectedCustomers: string[];
  selectedDestinationId: any;
  selectedDestinationIdsForMultiSelect: any[];
  selectedOptIn: any;
  selectedRepeatId: any;
  selectedReward?: any;
  selectedTimeOfDayId: any;
  sendError?: any;
  sendLoading: boolean;
  startDate: any;
  startTime: string;
  startTimeEndRestriction: string;
  startTimeRestriction: string;
  step: number;
  subject: string;
  subTitle: string;
  successfulPlatforms: string;
  templateId: string;
  thirdPartyDateRestrictions: any;
  thirdPartyMap: any;
  thirdPartyPromoCreatedId: string;
  timeOfDayOptions: any[];
  treatLength: number;
  treats: any[];
}

export const initialState: State = {
  allCustomerTypes: [],
  allDaysBlocked: false,
  areDatesWithinSevenDays: false,
  availableCustomerTypes: [],
  campaignName: '',
  cceModalAlreadyShown: false,
  communityCareDateRestrictions: [],
  count: null,
  currencyCode: '',
  defaultMessageForApp: '',
  destinationOptions: [],
  disabledDays: [],
  disabledTimes: { start: [], end: [] },
  disabledTimesMap: {},
  duplicateRecipients: [],
  email: {},
  endDate: moment().add('30', 'days'),
  endDateRestriction: moment,
  endTime: SharedConstants.latestTimeOption,
  endTimeEndRestriction: '',
  endTimeRestriction: '',
  failedPlatforms: '',
  flow: [],
  freeItemInCartCFADeliveryEnabled: true,
  freeItemInCartDoorDashEnabled: true,
  freeItemInCartFlags: {},
  freeItemInCartPickupEnabled: true,
  includeActiveTreats: false,
  invalidated: false,
  loading: true,
  maxDaysAllowed: 365,
  menuItems: [],
  message: '',
  offerRecipients: [],
  optInOptions: [],
  organizations:[],
  overlappingEngagementType: '',
  repeatOptions: [],
  repeatPromotionSubId: '',
  platforms: [],
  previewHtml: '',
  primaryText: 'Next',
  pushMessageText: '',
  pushMessageTitle: '',
  selectedCustomers: [],
  selectedDestinationId: '',
  selectedDestinationIdsForMultiSelect: [],
  selectedOptIn: {},
  selectedRepeatId: '',
  selectedTimeOfDayId: '',
  sendLoading: false,
  startDate: moment(),
  startTime: getNextFifteenMinuteIncrement(),
  startTimeEndRestriction: '',
  startTimeRestriction: getNextFifteenMinuteIncrement(),
  step: 0,
  subject: '',
  subTitle: '',
  successfulPlatforms: '',
  templateId: '',
  thirdPartyDateRestrictions: {},
  thirdPartyMap: {},
  thirdPartyPromoCreatedId: '',
  timeOfDayOptions: [],
  treatLength: 30,
  treats: []
};

export function getCheckSelectedAll(availableCustomerTypes) {
  let totalSelected = 0;
  let allSelected = false;

  availableCustomerTypes.map(type =>{
    totalSelected = type.selected ? totalSelected + 1 : totalSelected;
    allSelected = type.displayTitle.includes('All') ? type.selected : allSelected;
  });

  if (allSelected) {
    availableCustomerTypes.map(type => {
      return type.selected = true;
    });
  } else if (totalSelected > 0 && (totalSelected === availableCustomerTypes.length - 1)) {
    const index = availableCustomerTypes.indexOf(find(propEq('displayTitle', 'All'))(availableCustomerTypes));
    if (index !== -1) {
      availableCustomerTypes[index].selected = true;
    }
  }

  return availableCustomerTypes;
}

export function getEventDatesAndTimes(eventState) {
  let {
    startDate,
    startTime,
    endDate,
    endTime,
    engagementType,
  } = eventState;
  let startTimeRestriction = SharedConstants.earliestTimeOption;
  let endTimeRestriction = SharedConstants.earliestTimeOption;
  let timeFormat = 'h:mm a';
  let beginningOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  let startDateStartOfDay = clone(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  // Set start time and start time restriction
  if (beginningOfToday.diff(startDateStartOfDay, 'days') === 0) {
    startTimeRestriction = getNextTimeByFifteenMinuteIncrements(0);

    // If currently selected start time is before the restriction, set it to null
    if (moment(startTime, timeFormat).isBefore(moment(startTimeRestriction, timeFormat))) {
      startTime = null;
    }
  }
  // If endDate is same day as startDate, set time restrictions
  if (startDate.diff(endDate, 'days') === 0
      || (engagementType == EngageConstants.freeItemInCartPromotion || engagementType == EngageConstants.bonusPoint)) {
    endTimeRestriction = startTime ? startTime : startTimeRestriction;

    // If currently selected end time is before the restriction, set it to null
    if (moment(endTime, timeFormat).isBefore(moment(endTimeRestriction, timeFormat))) {
      endTime = null;
    }
  }

  return {
    startDate,
    startTime,
    startTimeRestriction,
    endDate,
    endTime,
    endTimeRestriction
  };
}

// Gets the nearest fifteen minute increment to use for the start time restriction
export function getNextFifteenMinuteIncrement() {
  const minutesToAdd = 15 - (moment().minute() % 15);
  return moment().add(minutesToAdd, 'minutes').format('h:mm a');
}

// Gets the nearest fifteen minute increment and then adds the desired number of 15 minute intervals
export function getNextTimeByFifteenMinuteIncrements(numberOfIncrements) {
  const remainder = 15 - (moment().minute() % 15);
  const minutesToAdd = numberOfIncrements * 15 + remainder;
  return moment().add(minutesToAdd, 'minutes').format('h:mm a');
}

// Return the earliest possible start based on the first entry and the latest possible end by the final entry
export function getTimeRestrictionsBasedOnSelectedReward(validTimeOfDay, startTime, endTime, startTimeRestriction) {
  // Here are the day-part time distributions:
  // Breakfast: Open - 10:30 AM
  // Afternoon: 10:30 AM - close
  // All Day and Crossover: no restriction
  let timeRestrictions = {
    endTime: endTime,
    endTimeRestriction: '11:45 pm',
    startTime: startTime,
    startTimeRestriction: startTimeRestriction
  };

  switch (validTimeOfDay) {
    case DiscoverConstants.breakfastDayPart: {
      startTime = moment(startTime, 'h:mm a').isAfter(moment('10:30 am', 'h:mm a')) ? null : startTime;
      endTime = moment(endTime, 'h:mm a').isAfter(moment('10:30 am', 'h:mm a')) ? null : endTime;
      timeRestrictions = {
        ...timeRestrictions,
        startTime,
        endTime,
        endTimeRestriction: '10:30 am'
      }
      break;
    }

    case DiscoverConstants.afternoonDayPart: {
      startTime = moment(startTime, 'h:mm a').isBefore(moment('10:30 am', 'h:mm a')) ? null : startTime;
      endTime = moment(endTime, 'h:mm a').isBefore(moment('10:30 am', 'h:mm a')) ? null : endTime;
      timeRestrictions = {
        ...timeRestrictions,
        startTime,
        endTime,
        startTimeRestriction: '10:30 am'
      }
      break;
    }
  }

  return timeRestrictions;
}

export function getTimesWithDisabledTimes(startDate, endDate, disabledTimesMap: any, startTime, endTime, endDateRestriction, cceEvents) {
  let disabledTimes = { start: [], end: [] };
  let newEndTime = clone(endTime);
  let newStartTime = clone(startTime);

  // Sort disabled times, just in case they are out of order
  let endDateDisabledTimes = disabledTimesMap[endDate.format('MM/DD/YYYY')] ? disabledTimesMap[endDate.format('MM/DD/YYYY')] : [];
  endDateDisabledTimes.sort((a, b) =>  moment(a,'h:mma').isBefore(moment(b,'h:mma'), 'minutes') ? -1 : 1);
  let startDateDisabledTimes = disabledTimesMap[startDate.format('MM/DD/YYYY')] ? disabledTimesMap[startDate.format('MM/DD/YYYY')] : [];
  startDateDisabledTimes.sort((a,b) => moment(a,'h:mma').isBefore(moment(b,'h:mma'), 'minutes') ? -1 : 1);

  // Double check there are no events between current start and end
  cceEvents.forEach((scheduledEvent) => {
    let eventStart = moment.unix(clone(scheduledEvent.start));
    let eventTime = moment(eventStart.format('h:mma'), 'h:mma');

    if (eventStart.isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), 'day','()')) {
      endDate = clone(eventStart);
      endDateRestriction = clone(endDate);
      newEndTime = moment(newEndTime, 'h:mma').isAfter(eventTime) ? eventTime.format('h:mm a') : newEndTime;
    }
  })

  // If it is not a single day event
  if (!startDate.isSame(endDate, 'day')) {
    // Since it's not a single day event and start time is before the disabled times, end date needs to move
    if (moment(newStartTime, 'h:mma').isBefore(moment(startDateDisabledTimes[startDateDisabledTimes.length -1], 'h:mma'),'minutes')) {
      return getTimesWithDisabledTimes(startDate, clone(startDate), disabledTimesMap, newStartTime, endTime, endDateRestriction, cceEvents);
    }

    // Find the first available time, after or same as the current start that's not disabled
    while(startDateDisabledTimes.includes(newStartTime) && newStartTime != SharedConstants.latestTimeOption) {
      newStartTime = moment(newStartTime,'h:mma').add(15, 'minutes').format('h:mm a');
    }

    // If there are no times available set the start to null
    newStartTime = startDateDisabledTimes.includes(newStartTime) ? null : newStartTime;

    // If the end time is after any disabled time && it is not a single day event, then that time needs to be moved
    if (moment(newEndTime, 'h:mma').isAfter(moment(endDateDisabledTimes[0], 'h:mma'))) {
      // If the earliest option is disabled, just set to null, otherwise 15 min before the earliest disabled time
      newEndTime = endDateDisabledTimes[0] != SharedConstants.earliestTimeOption
        ? moment(endDateDisabledTimes[0], 'h:mma').subtract(15, 'minutes').format('h:mm a') : null;
    }
  } else {
    // If they start and end dates are the same, need to iterate until you find a time opening
    if (startDateDisabledTimes.length > 1) {
      // Set start based on current day and if it should be disabled
      let startTime = startDateDisabledTimes.includes(newStartTime) ? SharedConstants.earliestTimeOption : newStartTime;
      startTime = moment().dayOfYear() === startDate.dayOfYear() && startTime == SharedConstants.earliestTimeOption
        ? getNextTimeByFifteenMinuteIncrements(1) : startTime;
      while (startDateDisabledTimes.includes(startTime) && startTime != SharedConstants.latestTimeOption) {
        startTime = moment(startTime, 'h:mma').add(15, 'minutes').format('h:mm a');
        // If it is not a 15-minute gap, then we can't schedule an event
        if (startDateDisabledTimes.includes(startTime) && startTime != SharedConstants.latestTimeOption) {
          startTime = moment(startTime, 'h:mma').add(15, 'minutes').format('h:mm a');
        }
      }
      newStartTime = startTime != SharedConstants.latestTimeOption ? startTime : null;
    }

    // If the end time is after the new start time but there is a disabled time in between, move end time
    if (moment(newEndTime, 'h:mma').isAfter(moment(newStartTime, 'h:mma'))) {
      let betweenFound = false;
      startDateDisabledTimes.forEach(time => {
        if (!betweenFound && moment(time, 'h:mma').isBetween(moment(newStartTime,'h:mma'), moment(newEndTime, 'h:mma'))) {
          newEndTime = moment(time, 'h:mma').subtract(15,'minutes').format('h:mm a');
        }
      });
    } else {
      // If end time is before start, move it to after
      newEndTime = newStartTime ? moment(newStartTime, 'h:mma').add(15, 'minutes').format('h:mm a') : null;
      newEndTime = endDateDisabledTimes.includes(newEndTime) ? null : newEndTime;
    }
  }

  // If a time after the current end is disabled, everything after that time should be disabled
  let laterTimeTracker = newEndTime;
  let shouldDisableAfter = false;
  let tempEndDateDisabledTimes = clone(endDateDisabledTimes);
  while(moment(laterTimeTracker,'h:mma').isBefore(moment(SharedConstants.latestTimeOption, 'h:mma'))) {
    if (!shouldDisableAfter && tempEndDateDisabledTimes.includes(laterTimeTracker)) {
      shouldDisableAfter = true;
    } else if(shouldDisableAfter  && !endDateDisabledTimes.includes(laterTimeTracker)) {
      tempEndDateDisabledTimes.push(laterTimeTracker);
    }
    laterTimeTracker = moment(laterTimeTracker,'h:mma').add(15, 'minutes').format('h:mm a');
  }

  if (shouldDisableAfter) {
    tempEndDateDisabledTimes.push(SharedConstants.latestTimeOption);
    endDateRestriction = clone(endDate);
  }

  if (endDateRestriction === null || (!tempEndDateDisabledTimes.includes(SharedConstants.latestTimeOption)
      && moment(endDateRestriction).isSame(endDate, 'day'))) {
    let nextRestriction = clone(endDate).add(3, 'years');
    cceEvents.forEach(event => {
      let startEvent = moment.unix(event.start);
      if (startEvent.isAfter(endDate, 'day') && startEvent.isBefore(nextRestriction, 'day')) {
        nextRestriction = clone(startEvent);
      }
    });
    endDateRestriction = nextRestriction;
  }

  disabledTimes = {
    end: tempEndDateDisabledTimes,
    start: startDateDisabledTimes
  }

  return {
    disabledTimes,
    endDate,
    endDateRestriction,
    newEndTime,
    newStartTime,
    startDate
  };
}

export function reducer(state = initialState, action: engagement.Actions | user.Actions): State {
  switch (action.type) {
    case engagement.ActionTypes.CCE_UPDATE_MODAL_SHOWN: {
      return {
        ...state,
        cceModalAlreadyShown: true
      }
    }

    case engagement.ActionTypes.CREATE_AUTOMATION_FAILURE:
    case engagement.ActionTypes.EDIT_AUTOMATION_FAILURE:
    case engagement.ActionTypes.SEND_FAILURE:
    case engagement.ActionTypes.STOP_AUTOMATION_FAILURE:
    case engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_FAILURE:
    case engagement.ActionTypes.STOP_FREE_IN_CART_FAILURE:{
      const error = action.payload;

      return {
        ...state,
        loading: false,
        sendError: error,
        sendLoading: false
      };
    }

    case engagement.ActionTypes.CREATE_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.EDIT_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.SEND_EMAIL_SUCCESS:
    case engagement.ActionTypes.SEND_PROMOTION_SUCCESS:
    case engagement.ActionTypes.SEND_REWARD_SUCCESS:
    case engagement.ActionTypes.STOP_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS:{
      return { ...state, loading: false, sendLoading: false}
    }

    case engagement.ActionTypes.EDIT_AUTOMATION:
    case engagement.ActionTypes.SEND_ENGAGEMENT: {
      return { ...state, sendError: null, sendLoading: true };
    }

    case engagement.ActionTypes.FETCH_COMMUNITY_CARE_DATE_RESTRICTION_SUCCESS: {
      const dateRestriction = action.payload;
      let startDate = clone(state.startDate);
      let endDate = clone(state.endDate);
      let startTime = state.startTime;
      let endTime = state.endTime;
      let disabledDays = []
      let disabledTimesMap = {}
      let endDateRestriction = state.endDateRestriction;
      let events = dateRestriction ? dateRestriction : [];

      events.forEach((event) => {
        let endEventTime = moment.unix(event.end).format('h:mm a');
        let endEventDate = moment.unix(event.end);
        let startEventTime = moment.unix(event.start).format('h:mm a');
        let startEventDate = moment.unix(event.start);
        let dayInEvent = moment.unix(event.start).add(1, 'day');
        // All non start/end dates should be disabled.
        while(dayInEvent.isBefore(endEventDate, 'day')) {
          disabledDays.push(dayInEvent.format('YYYY-MM-DD'));
          // If start is on a disabled day move to end of the event
          if (startDate.isSame(dayInEvent)) {
            startDate = endEventDate;
            endDate = endDate.isBefore(startDate) ? startDate : endDate;
          }
          dayInEvent.add(1, 'day');
        }

        // If start date is before event but end date is after, then move end date
        if (startDate.isSameOrBefore(startEventDate, 'day') && endDate.isAfter(startEventDate, 'day')) {
          endDate = startEventDate;
          endDateRestriction = clone(endDate);
        // If start date is after the event start but before the end, then move start date
        } else if (startDate.isAfter(startEventDate) && startDate.isSameOrBefore(endEventDate)) {
          startDate = endEventDate;
          startTime = endEventTime;
        // If the current end date restriction is after this event and the event being planned is before it, then update the end restriction
        } else if ((moment(endDateRestriction).isAfter(startEventDate) && startEventDate.isAfter(endDate))) {
          endDateRestriction = startEventDate;
        }

        // If the event is a single day, then disable the times that it is happening
        if (startEventDate.isSame(endEventDate, 'day')) {
          let time = clone(startEventTime);
          let dateString = startEventDate.format('MM/DD/YYYY');
          while (moment(time,'h:mma').isSameOrBefore(moment(endEventTime, 'h:mma'))
                 && moment(time,'h:mma').isBefore(moment(SharedConstants.latestTimeOption, 'h:mma'))) {
            if (disabledTimesMap[dateString] && !disabledTimesMap[dateString].includes(time)) {
              disabledTimesMap[dateString].push(time);
            } else {
              disabledTimesMap[dateString] = [time];
            }
            time = moment(time,'h:mma').add(15, 'minutes').format('h:mm a');
          }
          if (endEventTime === SharedConstants.latestTimeOption){
            disabledTimesMap[dateString].push(SharedConstants.latestTimeOption)
          }
        // If the event is multi day, then disable times after start on start day and before end on end day
        } else {
          let startTracker = startEventTime;
          let startString = startEventDate.format('MM/DD/YYYY');
          disabledTimesMap[startString]= [startTracker];

          while(moment(startTracker, 'h:mma').isBefore(moment(SharedConstants.latestTimeOption, 'h:mma'))) {
            if (disabledTimesMap[startString] && !disabledTimesMap[startString].includes(startTracker)) {
              disabledTimesMap[startString].push(startTracker);
            }
            startTracker = moment(startTracker, 'h:mma').add(15,'minutes').format('h:mm a');
          }

          disabledTimesMap[startString].push(SharedConstants.latestTimeOption);
          let endTracker = SharedConstants.earliestTimeOption;
          let endString = endEventDate.format('MM/DD/YYYY');
          disabledTimesMap[endString] = [endEventTime];

          while(moment(endTracker, 'h:mma').isBefore(moment(endEventTime, 'h:mma'))) {
            if (disabledTimesMap[endString] && !disabledTimesMap[endString].includes(endTracker)) {
              disabledTimesMap[endString].push(endTracker);
            } else {
              disabledTimesMap[endString] = [endTracker];
            }
            endTracker = moment(endTracker, 'h:mma').add(15,'minutes').format('h:mm a');
          }
        }
      });

      let datesAndTimes = getTimesWithDisabledTimes(startDate, endDate, disabledTimesMap, startTime, endTime, endDateRestriction, events);
      const communityCareDateRestrictions = events;
      const endTimeRestriction = moment().isSame(datesAndTimes.endDate.format('YYYY-MM-DD')) ? getNextFifteenMinuteIncrement() : null;

      return {
        ...state,
        communityCareDateRestrictions,
        disabledDays,
        disabledTimes : datesAndTimes.disabledTimes,
        disabledTimesMap,
        endDate: moment(endDate),
        endDateRestriction: datesAndTimes.endDateRestriction,
        endTime: datesAndTimes.newEndTime,
        endTimeRestriction,
        startDate: moment(startDate),
        startTime: datesAndTimes.newStartTime
      };
    }

    case engagement.ActionTypes.FETCH_ENGAGEMENT_DETAILS_SUCCESS: {
      const engagement = action.payload;
      const freeItemInCartCFADeliveryEnabled = state.freeItemInCartCFADeliveryEnabled;
      const freeItemInCartDoorDashEnabled = state.freeItemInCartDoorDashEnabled;
      const freeItemInCartPickupEnabled = state.freeItemInCartPickupEnabled;
      let allCustomerTypes = [];
      let availableCustomerTypes = [];
      let campaignName = state.campaignName;
      let count = 0;
      let defaultMessageForApp;
      let destinationOptions = [];
      let endDateRestriction = state.endDateRestriction;
      let menuItems = [];
      let message;
      let optInOptions = [];
      let platforms = [];
      let pushMessageText;
      let pushMessageTitle;
      let repeatOptions = [];
      let selectedDestinationId = '';
      let selectedDestinationIdsForMultiSelect = [];
      let selectedOptIn = {};
      let selectedRepeatId = '';
      let selectedReward;
      let selectedTimeOfDayId = '';
      let subject;
      let themeId;
      let timeOfDayOptions = [];
      let treatLength;

      if (state.engagementMode === DiscoverConstants.edit) {
        subject = engagement.campaignAutomation.emailSubject;
        message = engagement.campaignAutomation.emailText;
        defaultMessageForApp = engagement.campaignAutomation.treatMessageForApp;
        treatLength = Number(engagement.campaignAutomation.treatValidityDays);
        selectedReward = find(propEq('awardId', engagement.campaignAutomation.awardId))(engagement.treats);
      } else {
        if (state.engagementType === DiscoverConstants.reward || state.engagementType === DiscoverConstants.automation) {
          message = engagement.treats[0].customizedMessage;
          subject = engagement.treatMetadata ? engagement.treatMetadata.subject : '';
          defaultMessageForApp = engagement.treatMetadata ? engagement.treatMetadata.defaultMessageForApp : '';
          if (engagement.treats[0].endDateLiteral) {
            endDateRestriction = moment(engagement.treats[0].endDateLiteral);
          }
          let headerImage = engagement.treatMetadata ? engagement.treatMetadata.image : '';
          let mobileHeaderImage = engagement.treatMetadata ? engagement.treatMetadata.mobileImage : '';
          count = engagement.offerRecipients ? engagement.offerRecipients.length : 0;
          selectedReward = { ...engagement.treats[0], headerImage, mobileHeaderImage };
        } else if (state.engagementType === DiscoverConstants.promotion) {
          timeOfDayOptions = engagement.promotionPointsMetaData.timeOfDayOptions;
          destinationOptions = engagement.promotionPointsMetaData.destinationOptions;
          repeatOptions = engagement.promotionPointsMetaData.repeatOptions;

          // Check if any destination options are location restricted
          destinationOptions.forEach(destinationOption => {
            if (destinationOption.locations && engagement.locationNumber
                && destinationOption.locations.indexOf(engagement.locationNumber) === -1) {
              const index = destinationOptions.indexOf(find(propEq('id', destinationOption.id))(destinationOptions));
              destinationOptions.splice(index, 1);
            }
          });

          selectedDestinationId = destinationOptions[0].id;
          selectedDestinationIdsForMultiSelect = [destinationOptions[0].id];
          selectedTimeOfDayId = timeOfDayOptions[0].id;
          selectedRepeatId = repeatOptions[0].id;
        } else if (state.engagementType === DiscoverConstants.categoryCommunityCare) {
          optInOptions = engagement.communityCaresMetaData.optInOptions;
          selectedOptIn = optInOptions[0];
        } else if (state.engagementType === DiscoverConstants.thirdPartyPromotion) {
          let allPlatforms = clone(engagement.thirdPartyPromotionMetaData.platforms);
          platforms = clone(engagement.thirdPartyPromotionMetaData.platforms);
          availableCustomerTypes = clone(engagement.thirdPartyPromotionMetaData.customerTypes);
          allCustomerTypes = clone(engagement.thirdPartyPromotionMetaData.customerTypes);
          menuItems = clone(engagement.thirdPartyPromotionMetaData.menuItems);

          allPlatforms.forEach(platform => {
            if (!state.thirdPartyMap[platform.id]) {
              let index = platforms.indexOf(find(propEq('id', platform.id))(platforms));
              platforms.splice(index, 1);
            } else if (platform.selected){
              allCustomerTypes.forEach(type =>{
                let displayIndex = type.displayFor.indexOf(platform.id);
                if (displayIndex == -1){
                  availableCustomerTypes.splice(availableCustomerTypes.indexOf(type), 1)
                }
              });
            }
          });

          menuItems.forEach(menuItem => {
            if (menuItem.menuItemSelected) {
              selectedReward = menuItem;
              campaignName = 'Free ' + selectedReward.name;
            }
          });
          availableCustomerTypes = getCheckSelectedAll(availableCustomerTypes);
        } else if (state.engagementType === DiscoverConstants.freeItemInCartPromotion) {
          allCustomerTypes = clone(engagement.freeItemInCartPromotionMetaData.customerTypes);
          menuItems = clone(engagement.freeItemInCartPromotionMetaData.menuItems);
          menuItems.forEach(menuItem => {
            if (menuItem.menuItemSelected) {
              selectedReward = menuItem;
              campaignName = 'Free ' + selectedReward.name;
            }
          });
          timeOfDayOptions = engagement.freeItemInCartPromotionMetaData.timeOfDayOptions;
          destinationOptions = engagement.freeItemInCartPromotionMetaData.destinationOptions;
          repeatOptions = engagement.freeItemInCartPromotionMetaData.repeatOptions;
          selectedTimeOfDayId = timeOfDayOptions[0].id;
          selectedRepeatId = repeatOptions[0].id;
          availableCustomerTypes = clone(engagement.freeItemInCartPromotionMetaData.customerTypes).splice(0,4);

          // Remove delivery destination option if cfaDelivery flag is off
          if (!freeItemInCartCFADeliveryEnabled) {
            const index = destinationOptions.indexOf(find(propEq('id',
              DiscoverConstants.freeItemInCartCFADeliveryDestinationOption))(destinationOptions));
            destinationOptions.splice(index, 1);
          }
          // Remove checkoutWithDD destination option if doorDash flag is off
          if (!freeItemInCartDoorDashEnabled) {
            const index = destinationOptions.indexOf(find(propEq('id',
              DiscoverConstants.freeItemInCartDoorDashDestinationOption))(destinationOptions));
            destinationOptions.splice(index, 1);
          }
          // Remove carryOut, driveThru, curbside, and dineIn destination options if pickup flag is off
          if (!freeItemInCartPickupEnabled) {
            destinationOptions.splice(destinationOptions.indexOf(find(propEq('id', 'carryOut'))(destinationOptions)), 1);
            destinationOptions.splice(destinationOptions.indexOf(find(propEq('id', 'driveThru'))(destinationOptions)), 1);
            destinationOptions.splice(destinationOptions.indexOf(find(propEq('id', 'curbside'))(destinationOptions)), 1);
            destinationOptions.splice(destinationOptions.indexOf(find(propEq('id', 'dineIn'))(destinationOptions)), 1);
          }
          // Remove 'any' destination option if there is now only 1 other option remaining (or 0)
          if (destinationOptions.length < 3) {
            destinationOptions.splice(destinationOptions.indexOf(find(propEq('id', 'any'))(destinationOptions)), 1);
          }
        } else {
          subject = engagement.email.subject;
          message = engagement.email.defaultText;
        }
      }

      const endDate = treatLength ? moment().add(treatLength, 'd') : state.endDate;
      treatLength = treatLength ? treatLength : Number(initialState.treatLength);

      if (engagement.treatMetadata) {
        themeId = engagement.treatMetadata.id ? engagement.treatMetadata.id : engagement.themeId;
        pushMessageText = engagement.treatMetadata.pushMsgBody ? engagement.treatMetadata.pushMsgBody : '';
        pushMessageTitle = engagement.treatMetadata.pushMsgTitle ? engagement.treatMetadata.pushMsgTitle : '';
      }

      return {
        ...state,
        ...engagement,
        ...engagement.campaignAutomation,
        ...engagement.treatMetadata,
        allCustomerTypes,
        availableCustomerTypes,
        campaignName,
        count,
        defaultMessageForApp,
        destinationOptions,
        endDate,
        endDateRestriction,
        loading: false,
        menuItems,
        message,
        optInOptions,
        platforms,
        pushMessageText,
        pushMessageTitle,
        repeatOptions,
        selectedDestinationId,
        selectedDestinationIdsForMultiSelect,
        selectedOptIn,
        selectedRepeatId,
        selectedReward,
        selectedTimeOfDayId,
        subject,
        themeId,
        timeOfDayOptions,
        treatLength,
      };
    }

    case engagement.ActionTypes.FETCH_INTERNAL_PROMO_DATE_RESTRICTION_SUCCESS: {
      const dateRestriction = action.payload;
      const disabledDays = dateRestriction ? dateRestriction.sort((a, b) => { return moment(a).isBefore(b) ? -1 : 1 }) : [];
      let allDaysBlocked = false;
      // The default FIC date range is 1 week, BPP is 30 days
      let endDate = state.engagementType === DiscoverConstants.freeItemInCartPromotion
        ? clone(state.startDate).add(7, 'days') : clone(state.startDate).add(30, 'days');
      let startDate = clone(state.startDate);
      const endDateRestriction = startDate.isBefore(state.endDateRestriction) ? state.endDateRestriction : moment().add(365, 'days');

      // If the start or end date are on a Sunday then push them to Monday
      if (startDate.weekday() === 0) {
        startDate.add(1, 'day');
      }
      if (endDate.weekday() === 0) {
        endDate.add(1, 'day');
      }

      if (disabledDays && disabledDays.length > 0) {
        let start = clone(startDate);
        allDaysBlocked = true;
        // Loop through all available days to determine if there are no selectable days
        // Stop loop when all days are tested or an available day is found
        while (allDaysBlocked === true && moment(start).isBefore(moment(endDateRestriction), 'day')) {
          for (let i = 0; i < disabledDays.length; i++) {
            // If the day is blocked off then go to the next date
            // Else, once we reach the end of the disabled list without finding an overlap we know there is an available date
            if (moment(disabledDays[i]).isSame(moment(start), 'day')) {
              i = disabledDays.length;
            } else if (i == disabledDays.length - 1) {
              allDaysBlocked = false;
            }
          }
          start = moment(start).add(1, 'day');
        }
      }

      // If there are available days but some are disabled check that the default values are not unavailable
      if (!allDaysBlocked && disabledDays && disabledDays.length > 0) {
        let restriction = null;
        disabledDays.forEach((day) => {
          // If the start or end date are in the disabled list then add a day and continue checking
          startDate = startDate.isSame(moment(day)) ? startDate.add(1, 'day') : startDate;
          restriction = startDate.isBefore(moment(day)) ? moment(day).add(-1, 'day') : restriction
          // If the new date would be a Sunday, instead push it another day to Monday
          if (startDate.weekday() === 0) {
            startDate.add(1, 'day');
          }
          // If restriction is sunday, push one day earlier
          if (restriction && restriction.weekday() === 0) {
            restriction.subtract(1, 'day');
          }
        });
        // Reset end date since start has moved, and check for restriction between start and end
        endDate = state.engagementType === DiscoverConstants.freeItemInCartPromotion
          ? clone(startDate).add(7, 'days') : clone(startDate).add(30, 'days');
        if (endDate.weekday() === 0) {
          endDate.add(1, 'day');
        }
        endDate = restriction !== null && restriction.isBefore(endDate, 'day') ? restriction: endDate;
        endDate = endDate.isBefore(startDate) ? clone(startDate) : endDate;
      }

      return {
        ...state,
        allDaysBlocked,
        disabledDays,
        endDate,
        startDate
      };
    }

    case engagement.ActionTypes.FETCH_THIRD_PARTY_DATE_RESTRICTION_SUCCESS: {
      const dateRestriction = action.payload;
      const blockStartDate = moment.unix(dateRestriction.blockStartDate);
      const blockEndDate = moment.unix(dateRestriction.blockEndDate);

      let startDate = moment().isBefore(blockStartDate) ? blockStartDate : moment();
      if (startDate.weekday() === 0) {
        startDate = startDate.add('1', 'days');
      }
      let endDate = clone(startDate).add(7, 'days');
      if (blockEndDate.isBefore(endDate)) {
        endDate = clone(blockEndDate);
      }
      const thirdPartyDateRestrictions = {
        doordash: dateRestriction.doordash,
        grubhub: dateRestriction.grubhub,
        local: dateRestriction.local,
        ubereats: dateRestriction.ubereats,
      };

      return {
        ...state,
        disabledDays: dateRestriction.local,
        endDate: endDate,
        endDateRestriction: clone(blockEndDate),
        startDate: startDate,
        thirdPartyDateRestrictions
      };
    }

    case engagement.ActionTypes.OPEN_CANCELLATION_DIALOG: {
      const { engagementType, engagementMode } = action.payload;
      return {
        ...state,
        engagementMode,
        engagementType,
        error: null,
        loading: false
      };
    }

    case engagement.ActionTypes.RECEIVED_ENGAGEMENT_FAILED: {
      const error = action.payload;

      return {
        ...state,
        error,
        loading: false
      };
    }

    case engagement.ActionTypes.SELECT_ENGAGEMENT_DATES: {
      const { endDate, startDate, treatLength } = action.payload;
      return { ...state, endDate, startDate, treatLength };
    }

    case engagement.ActionTypes.SELECT_NEW_REWARD: {
      let selectedReward = action.payload;
      let campaignName = state.campaignName;
      let endDateRestriction = clone(state.endDateRestriction);
      let endTime = state.endTime;
      let endTimeEndRestriction = state.endTimeEndRestriction;
      let endTimeRestriction = state.endTimeRestriction;
      let message = selectedReward.customizedMessage;
      let selectedTimeOfDayId = state.selectedTimeOfDayId;
      let startTime = state.startTime;
      let startTimeEndRestriction = state.startTimeEndRestriction;
      let startTimeRestriction = state.startTimeRestriction;
      let timeOfDayOptions = state.timeOfDayOptions;

      if (selectedReward.endDateLiteral) {
        endDateRestriction = moment(selectedReward.endDateLiteral);
      }

      if (state.engagementType === DiscoverConstants.thirdPartyPromotion
          || state.engagementType === DiscoverConstants.freeItemInCartPromotion) {
        campaignName = 'Free ' + selectedReward.name;
      }
      // Check if the new reward has time of day part restrictions
      if (state.engagementType === DiscoverConstants.freeItemInCartPromotion) {
        switch (selectedReward.validDayPart) {
          case DiscoverConstants.breakfastDayPart:
            // Disable for not breakfast or custom
            timeOfDayOptions.forEach((option) => {
              option.disabled = option.id !== DiscoverConstants.freeItemInCartBreakfastId
                && option.id !== DiscoverConstants.freeItemInCartCustomId;
            });
            selectedTimeOfDayId = (selectedTimeOfDayId !== DiscoverConstants.freeItemInCartBreakfastId
              && selectedTimeOfDayId !== DiscoverConstants.freeItemInCartCustomId)
              ? DiscoverConstants.freeItemInCartBreakfastId : selectedTimeOfDayId;
            break
          case DiscoverConstants.afternoonDayPart:
            // Disable for breakfast and any
            timeOfDayOptions.forEach((option) => {
              option.disabled = option.id == DiscoverConstants.freeItemInCartBreakfastId
                || option.id == DiscoverConstants.freeItemInCartAnyId;
            });
            selectedTimeOfDayId = (selectedTimeOfDayId == DiscoverConstants.freeItemInCartBreakfastId
              || selectedTimeOfDayId == DiscoverConstants.freeItemInCartAnyId)
              ? DiscoverConstants.freeItemInCartLunchId : selectedTimeOfDayId;
            break
          default:
            timeOfDayOptions.forEach((option) => {
              option.disabled = false;
            });
        }

        // Update the start and end time restrictions for custom time selection based on the new reward's time restrictions
        let previousStartTimeRestriction = state.startDate.isSame(moment(), 'day') ? state.startTimeRestriction : '12:00 am';
        const timeRestrictions
          = getTimeRestrictionsBasedOnSelectedReward(selectedReward.validDayPart, startTime, endTime, previousStartTimeRestriction);
        startTime = timeRestrictions.startTime;
        startTimeRestriction = timeRestrictions.startTimeRestriction;
        startTimeEndRestriction = timeRestrictions.endTimeRestriction;
        endTime = timeRestrictions.endTime;
        endTimeRestriction = (endTime === null && startTime !== null) || moment(startTime, 'h:mm a').isBefore(moment(endTime, 'h:mm a'))
          ? startTime : timeRestrictions.startTimeRestriction;
        endTimeEndRestriction = timeRestrictions.endTimeRestriction;
      }

      return {
        ...state,
        campaignName,
        endDateRestriction,
        endTime,
        endTimeEndRestriction,
        endTimeRestriction,
        message,
        selectedReward,
        selectedTimeOfDayId,
        startTime,
        startTimeEndRestriction,
        startTimeRestriction,
        timeOfDayOptions
      };
    }

    case engagement.ActionTypes.SELECT_OVERLAPPING_ENGAGEMENT_TYPE: {
      const overlappingEngagementType = action.payload;
      return { ...state, overlappingEngagementType };
    }

    case engagement.ActionTypes.SEND_COMMUNITY_CARE_EVENT_SUCCESS: {
      return { ...state, loading: false, sendLoading: false };
    }

    case engagement.ActionTypes.SEND_FREE_IN_CART_FAILURE:{
      const error = action.payload;
      return {
        ...state,
        sendError: error ? error : true,
        sendLoading: false
      }
    }

    case engagement.ActionTypes.SEND_FREE_IN_CART_SUCCESS: {
      return {
        ...state,
        failedPlatforms: '',
        loading: false,
        sendError: null,
        sendLoading: false,
        successfulPlatforms: ''
      };
    }

    case engagement.ActionTypes.SEND_THIRD_PARTY_PROMOTION_FAILURE:{
      const error = action.payload;

      if (error && error.createdIn) {
        let failedPlatformsMessage = '';
        let successfulPlatformsMessage = '';

        let failedPlatforms = filter(platform => platform.selected && !error.createdIn.includes(platform.id), clone(state.platforms));
        failedPlatforms.map(platform => {
          failedPlatformsMessage = failedPlatformsMessage.length > 0 ? failedPlatformsMessage + ' and ' + platform.name : platform.name;
        });

        let successfulPlatforms = filter(platform => error.createdIn.includes(platform.id), clone(state.platforms));
        successfulPlatforms.map(platform => {
          successfulPlatformsMessage = successfulPlatformsMessage.length > 0
            ? successfulPlatformsMessage + ' and ' + platform.name : platform.name;
        })

        return {
          ...state,
          failedPlatforms: failedPlatformsMessage,
          sendError: true,
          sendLoading: false,
          successfulPlatforms: successfulPlatformsMessage,
          thirdPartyPromoCreatedId: error.id
        }

      } else {
        return {
          ...state,
          sendError: error ? error : true,
          sendLoading: false
        }
      }
    }

    case engagement.ActionTypes.SEND_THIRD_PARTY_PROMOTION_SUCCESS: {
      const thirdPartyPromotion = action.payload;
      return {
        ...state,
        failedPlatforms: '',
        loading: false,
        sendError: null,
        sendLoading: false,
        successfulPlatforms: '',
        thirdPartyPromoCreatedId: thirdPartyPromotion.fieldId
      };
    }

    case engagement.ActionTypes.START_ONE_PAGE_FLOW: {
      const { tile, engagementType, engagementMode } = action.payload;
      let daysToAdd = initialState.treatLength;
      let endDateRestriction = initialState.endDateRestriction
      let startTime = initialState.startTime;
      let startTimeRestriction = initialState.startTimeRestriction;

      if (moment().weekday() === 0 && engagementType !== DiscoverConstants.automation) {
        daysToAdd = Number(daysToAdd) + 1;
      }

      const endDate = moment().add(daysToAdd, 'd');
      let campaignName = tile.descriptionText.summary;

      if (engagementType === DiscoverConstants.automation) {
        campaignName = `Automated ${campaignName}`;
      } else if (engagementType === DiscoverConstants.categoryCommunityCare) {
        startTime = getNextTimeByFifteenMinuteIncrements(1);
        startTimeRestriction = clone(startTime)
      } else if (engagementType === DiscoverConstants.freeItemInCartPromotion) {
        endDateRestriction = moment().add(365, 'days');
      }

      return {
        ...initialState,
        campaignName,
        cceModalAlreadyShown: state.cceModalAlreadyShown,
        count: tile.count,
        currencyCode: state.currencyCode,
        endDate,
        endDateRestriction,
        engagementMode,
        engagementType,
        error: null,
        freeItemInCartCFADeliveryEnabled: state.freeItemInCartCFADeliveryEnabled,
        freeItemInCartDoorDashEnabled: state.freeItemInCartDoorDashEnabled,
        freeItemInCartFlags: state.freeItemInCartFlags,
        freeItemInCartPickupEnabled: state.freeItemInCartPickupEnabled,
        flowId: tile.id,
        loading: true,
        startTime,
        startTimeRestriction,
        thirdPartyMap: state.thirdPartyMap
      };
    }

    case engagement.ActionTypes.STOP_AUTOMATION:
    case engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT:
    case engagement.ActionTypes.STOP_FREE_IN_CART: {
      return {
        ...state,
        engagementAction: DiscoverConstants.stop,
        sendError: null,
        sendLoading: true
      };
    }

    case engagement.ActionTypes.STOP_FREE_IN_CART_SUCCESS: {
      return {
        ...state,
        engagementType: DiscoverConstants.freeItemInCartPromotion,
        loading: false,
        sendLoading: false
      };
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT: {
      const payload = action.payload;
      return { ...state, ...payload };
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_CUSTOMER_TYPES: {
      const type = action.payload;
      let availableCustomerTypes = clone(state.availableCustomerTypes);

      availableCustomerTypes.map((currentType) => {
        currentType.selected = currentType.displayTitle === type.displayTitle ? type.selected : false;
        return currentType;
      });

      return {
        ... state,
        availableCustomerTypes
      }
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_DATES: {
      const dates = action.payload;
      const oldStartDate = clone(state.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const daysChanged = clone(dates.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).diff(oldStartDate, 'days');
      const oldEndDate = clone(state.endDate);
      const timeFormat = 'h:mm a';
      let newEndDate = clone(dates.endDate);
      let repeatOptions = clone(state.repeatOptions);
      let selectedRepeatId = clone(state.selectedRepeatId);
      let repeatPromotionSubId = clone(state.repeatPromotionSubId);
      let startTime = clone(state.startTime);
      let originalEndTime = clone(state.endTime);
      let startTimeRestriction = clone(state.startTimeRestriction);
      let newDisabledTimes = state.disabledTimes;
      let endDateRestriction = state.endDateRestriction;
      let eventState = { ...state, endDate: newEndDate };
      let { endTime, endTimeRestriction } = getEventDatesAndTimes(eventState);

      // If the start date changed also update the end date by that much
      if (daysChanged && state.engagementType !== DiscoverConstants.categoryCommunityCare) {
        newEndDate = clone(oldEndDate).add(daysChanged, 'days');
        // Check for sunday
        if (newEndDate.weekday() === 0) {
          newEndDate = newEndDate.add(-1,'day');
        }
        // If there are date restrictions, don't let the new endDate be a restricted date
        if (state.disabledDays) {
          state.disabledDays.forEach(dayString => {
            let day = moment(dayString);
            // If there is a restricted date between start and end, move end to first available date
            if (day.isAfter(dates.startDate) && (day.isBefore(newEndDate) || day.isSame(newEndDate))) {
              newEndDate = clone(day).add(-1, 'day');
            }
            // Check for sunday
            if (newEndDate.weekday() === 0) {
              newEndDate = newEndDate.add(-1,'day');
            }
          })
        }
      }

      // If the start date is the current day then do not let them schedule a time in the past
      if (dates.startDate.date() === moment().date() && dates.startDate.month() === moment().month()) {
        startTimeRestriction = getNextFifteenMinuteIncrement();
        // Reset the startTime if it was before the new time restriction
        if (moment(startTime, timeFormat).isBefore(moment(startTimeRestriction, timeFormat))) {
          startTime = null;
        }
      } else {
        startTimeRestriction = SharedConstants.earliestTimeOption;
      }

      // If the end date is the current day then do not let them schedule a time in the past
      if (newEndDate.date() === moment().date() && newEndDate.month() === moment().month()) {
        endTimeRestriction = getNextFifteenMinuteIncrement();
        // Reset the endTime if it was before the new time restriction
        if (moment(endTime, timeFormat).isBefore(moment(endTimeRestriction, timeFormat))) {
          endTime = null;
        }
      } else {
        endTimeRestriction = SharedConstants.earliestTimeOption;
      }

      // If start date and end date is same then do not let them schedule an end time before start time
      if (newEndDate.isSame(dates.startDate)) {
        endTimeRestriction = startTime ? startTime : startTimeRestriction;
        // Reset the endTime if it was before the new time restriction
        if (moment(endTime, timeFormat).isBefore(moment(endTimeRestriction, timeFormat))) {
          endTime = null;
        }
      }

      if (state.engagementType == DiscoverConstants.categoryCommunityCare) {
        if (dates.startDate.isAfter(newEndDate, 'day')) {
          newEndDate = clone(dates.startDate);
          endDateRestriction = null;
        }
        if (dates.startDate.isAfter(endDateRestriction, 'day')) {
          endDateRestriction = null;
        }
        let cceEvents = state.communityCareDateRestrictions;

        if (cceEvents.length > 0) {
          let originalStartTime = startTime === null ? startTimeRestriction : startTime;
          let datesAndTimes = getTimesWithDisabledTimes(dates.startDate, newEndDate, state.disabledTimesMap, originalStartTime,
            originalEndTime, endDateRestriction, cceEvents);
          startTime = datesAndTimes.newStartTime;
          endTime = datesAndTimes.newEndTime;
          newDisabledTimes = datesAndTimes.disabledTimes;
          endDateRestriction = datesAndTimes.endDateRestriction;
          dates.startDate = datesAndTimes.startDate;
          newEndDate = datesAndTimes.endDate
          endTimeRestriction = dates.startDate.isSame(newEndDate, 'day') ? startTime : null;
          endTimeRestriction = newEndDate.isSame(moment().format('YYYY-MM-DD'), 'day')
            ? getNextFifteenMinuteIncrement() : endTimeRestriction;
        }
      }

      // If the start date and end date are within a week then don't allow for the repeat weekly option
      if (newEndDate && dates && dates.startDate && newEndDate.diff(dates.startDate, 'days') < 7) {
        repeatOptions.forEach((repeatPromotion) => {
          repeatPromotion.selected = repeatPromotion.id === SharedConstants.dailyRepeatPromotion;
        });
        return {
          ...state,
          areDatesWithinSevenDays: true,
          disabledTimes: newDisabledTimes,
          endDate: newEndDate,
          endDateRestriction,
          endTime,
          endTimeRestriction,
          repeatOptions,
          repeatPromotionSubId: '',
          selectedRepeatId: SharedConstants.dailyRepeatPromotion,
          startDate: dates.startDate,
          startTime,
          startTimeRestriction
        };
      } else {
        return {
          ...state,
          areDatesWithinSevenDays: false,
          disabledTimes: newDisabledTimes,
          endDate: newEndDate,
          endDateRestriction,
          endTime,
          endTimeRestriction,
          repeatPromotionSubId,
          selectedRepeatId,
          startDate: dates.startDate,
          startTime,
          startTimeRestriction
        };
      }
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_MULTI_SELECT_DESTINATIONS: {
      const payload = action.payload;
      let existingDestinationIds = state.selectedDestinationIdsForMultiSelect;
      const destinationOptions = state.destinationOptions;
      let newDestinationIds = clone(payload.selectedDestinationIds);
      let newType;
      let allSubOptionsSelected = true;

      if (state.engagementType === DiscoverConstants.freeItemInCartPromotion) {
        newDestinationIds.forEach(id => {
          if (existingDestinationIds && !existingDestinationIds.includes(id)) {
            newType = find(propEq('id', id))(destinationOptions).type;
          }
        })
        if (newType) {
          destinationOptions.forEach(option => {
            if (newDestinationIds.includes(option.id) && option.type != newType) {
              newDestinationIds.splice(newDestinationIds.indexOf(option.id),1);
            }
          })
        }
      } else {
        // Check if all options other than 'Any' are selected, then if so select 'Any' only
        destinationOptions.forEach((destinationOption) => {
          if (destinationOption.id !== 'any' && newDestinationIds.indexOf(destinationOption.id) < 0) {
            allSubOptionsSelected = false;
          }
        });

        // Unselect 'Any' if a different option is selected
        if (existingDestinationIds && existingDestinationIds.includes('any')) {
          newDestinationIds = newDestinationIds.slice(newDestinationIds.indexOf('any') + 1, newDestinationIds.length);
          // Check if newly selected option is 'Any', then if so deselect all other options
        } else if (newDestinationIds && newDestinationIds.includes('any') || allSubOptionsSelected) {
          newDestinationIds = ['any'];
        }
      }


      return { ...state, selectedDestinationIdsForMultiSelect: newDestinationIds };
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_PLATFORMS: {
      const platforms = action.payload.platforms;
      let allCustomerTypes = clone(state.allCustomerTypes);
      let availableCustomerTypes = clone(state.allCustomerTypes);
      let disabledDays = [];
      let allDaysBlocked = false;
      const thirdPartyDateRestrictions = clone(state.thirdPartyDateRestrictions);
      let startDate = clone(state.startDate);
      let endDate = clone(state.endDate);
      let endDateRestriction = clone(state.endDateRestriction);

      // Maintain current customer selections
      availableCustomerTypes.map(type => {
        const currentType = find(propEq('displayTitle', type.displayTitle))(state.availableCustomerTypes);
        return type.selected = currentType ? currentType.selected : type.selected;
      });

      platforms.forEach(platform => {
        // If a platform is selected, check if it prevents any customer types from being available
        if (platform.selected) {
          allCustomerTypes.forEach(type => {
            let displayIndex = type.displayFor.indexOf(platform.id);
            if (displayIndex === -1) {
              let index = availableCustomerTypes.indexOf(find(propEq('displayTitle', type.displayTitle))(availableCustomerTypes));
              if (index !== -1) {
                availableCustomerTypes.splice(index, 1);
              }
            }
          });
          // If a platform is selected, add any applicable restricted days to the disabled list
          if (thirdPartyDateRestrictions && thirdPartyDateRestrictions[platform.id]) {
            thirdPartyDateRestrictions[platform.id].forEach((day) => {
              disabledDays.push(day);
            });
          }
        }
      });

      // Add any local promotions to the disabled days
      if (thirdPartyDateRestrictions && thirdPartyDateRestrictions.local) {
        thirdPartyDateRestrictions.local.forEach((day) => {
          disabledDays.push(day);
        });
      }
      // Check the current start and end dates based on restriction
      if (disabledDays && disabledDays.length > 0) {
        disabledDays.sort((a, b) => moment(a).isBefore(b) ? -1 : 1);
        let start = moment();
        allDaysBlocked = true;

        // Loop through all available days to determine if there are no selectable days
        // Stop loop when all days are tested or an available day is found
        while (allDaysBlocked === true && moment(start).isBefore(moment(endDateRestriction), 'day')) {
          for (let i = 0; i < disabledDays.length; i++) {
            // If the day is blocked off then go to the next date
            // Else, once we reach the end of the disabled list without finding an overlap we know there is an available date
            if (moment(disabledDays[i]).isSame(moment(start), 'day')) {
              i = disabledDays.length;
            } else if (i == disabledDays.length - 1) {
              allDaysBlocked = false;
            }
          }
          start = moment(start).add(1, 'day');
        }
      }

      // Only check date restrictions if there are available days
      if (!allDaysBlocked) {
        disabledDays.forEach(dayString => {
          let day = moment(dayString);
          if (day.isSame(startDate, 'day')) {
            // If start is restricted move the promo  a day
            startDate = startDate.add(1, 'day');
            endDate.add(1, 'day')
            // Check for sunday
            if (endDate.weekday() === 0) {
              endDate = endDate.add(1, 'day');
            }
            if (startDate.weekday() === 0) {
              startDate = startDate.add(1, 'day');
            }
          }

          if (day.isAfter(startDate) && (day.isBefore(endDate, 'day') || day.isSame(endDate, 'day'))) {
            // If there is a restricted date between start and end, move end to first available date
            endDate = clone(day).add(-1, 'day');
            // Check for sunday
            if (endDate.weekday() === 0) {
              endDate = endDate.add(-1, 'day');
            }
          }
        });
      }

      return {
        ...state,
        allDaysBlocked,
        availableCustomerTypes,
        disabledDays,
        endDate,
        platforms,
        startDate
      }
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_REPEAT_PROMOTION: {
      const selectedRepeatId = action.payload;
      let repeatPromotionSubId = '';
      let repeatOptions = state.repeatOptions;

      if (selectedRepeatId === SharedConstants.weeklyRepeatPromotion) {
        // Repeat this promotion - Weekly option - set Monday as the default value.
        repeatOptions.map((repeatPromotion) => {
          if (repeatPromotion.id === SharedConstants.weeklyRepeatPromotion) {
            repeatPromotionSubId = repeatPromotion.subOptions[0].id;
          }
        });
      }

      return {
        ...state,
        repeatPromotionSubId,
        selectedRepeatId
      }
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_START_TIME: {
      let newStartTime = action.payload;
      let eventState = { ...state, startTime: newStartTime };

      let {
        startTime,
        endTime,
        endTimeRestriction
      } = getEventDatesAndTimes(eventState);

      let newDisabledTimes = { };
      let endDateRestriction = clone(state.endDateRestriction)
      if (state.engagementType == DiscoverConstants.categoryCommunityCare) {
        const datesAndTimes = getTimesWithDisabledTimes(clone(state.startDate), clone(state.endDate), state.disabledTimesMap, startTime,
          endTime, endDateRestriction, state.communityCareDateRestrictions);
        endDateRestriction = datesAndTimes.endDateRestriction;
        endTime = datesAndTimes.newEndTime;
        newDisabledTimes = datesAndTimes.disabledTimes;
        startTime = datesAndTimes.newStartTime;
      }

      return {
        ...state,
        disabledTimes : newDisabledTimes,
        endDateRestriction,
        endTime,
        endTimeRestriction,
        startTime: startTime
      };
    }

    case engagement.ActionTypes.UPDATE_IS_AUTOMATED: {
      const { isAutomated, defaultCampaignName } = action.payload;
      let engagementType;
      let campaignName = defaultCampaignName;
      let treatLength = state.treatLength;

      if (isAutomated) {
        campaignName = `Automated ${campaignName}`;
        engagementType = DiscoverConstants.automation;
      } else {
        engagementType = DiscoverConstants.reward;
        if (moment().weekday() === 0) {
          treatLength = Number(treatLength) + 1;
        }
      }
      const endDate = moment().add(treatLength, 'd');

      return { ...state, campaignName, endDate, engagementType };
    }

    case engagement.ActionTypes.UPDATE_TIME_OF_DAY: {
      let selectedTimeOfDayId = action.payload;
      return { ...state, selectedTimeOfDayId };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      const location = action.payload;
      const freeItemInCartFlags = state.freeItemInCartFlags;

      let freeItemInCartCFADeliveryEnabled = state.freeItemInCartCFADeliveryEnabled;
      let freeItemInCartDoorDashEnabled = state.freeItemInCartDoorDashEnabled;
      let freeItemInCartPickupEnabled = state.freeItemInCartPickupEnabled;

      if (freeItemInCartFlags && freeItemInCartFlags.freeItemInCartCFADelivery) {
        freeItemInCartCFADeliveryEnabled = freeItemInCartFlags.freeItemInCartCFADelivery.enabledForAllLocations
          || (freeItemInCartFlags.freeItemInCartCFADelivery.enabledForLocations
          && freeItemInCartFlags.freeItemInCartCFADelivery.enabledForLocations.includes(location.locationNumber));
      }
      if (freeItemInCartFlags && freeItemInCartFlags.freeItemInCartDoorDash) {
        freeItemInCartDoorDashEnabled = freeItemInCartFlags.freeItemInCartDoorDash.enabledForAllLocations
          || (freeItemInCartFlags.freeItemInCartDoorDash.enabledForLocations
          && freeItemInCartFlags.freeItemInCartDoorDash.enabledForLocations.includes(location.locationNumber));
      }
      if (freeItemInCartFlags && freeItemInCartFlags.freeItemInCartPickup) {
        freeItemInCartPickupEnabled = freeItemInCartFlags.freeItemInCartPickup.enabledForAllLocations
          || (freeItemInCartFlags.freeItemInCartPickup.enabledForLocations
          && freeItemInCartFlags.freeItemInCartPickup.enabledForLocations.includes(location.locationNumber));
      }

      return {
        ... state,
        cceModalAlreadyShown: false,
        currencyCode: location.currencyCode,
        endDate: moment(),
        freeItemInCartCFADeliveryEnabled,
        freeItemInCartDoorDashEnabled,
        freeItemInCartPickupEnabled,
        startDate: moment(),
        thirdPartyMap: {
          doordash: location.doordash,
          grubhub: location.grubhub,
          ubereats: location.ubereats
        }
      }
    }

    case user.ActionTypes.LAUNCH_DARKLY_INITIALIZED: {
      const LDFlags = action.payload;
      const freeItemInCartFlags = {
        freeItemInCartCFADelivery: LDFlags.freeItemInCartCFADelivery,
        freeItemInCartDoorDash: LDFlags.freeItemInCartDoorDash,
        freeItemInCartPickup: LDFlags.freeItemInCartPickup
      }

      return {
        ...state,
        freeItemInCartFlags
      }
    }

    default: {
      return state;
    }
  }
}

export const getAreDatesWithinSevenDays = (state: State) => state.areDatesWithinSevenDays;

export const getAutomationAction = (state: State) => state.engagementAction;

export const getCCEModalAlreadyShown = (state:State) => state.cceModalAlreadyShown;

export const getCurrentCommunityCareEvents = (state:State) => state.communityCareDateRestrictions;

export const getDestinationOptions = (state: State) => state.destinationOptions;

export const getDisabledDays = (state: State) => state.disabledDays;

export const getDisabledTimes = (state:State) => state.disabledTimes;

export const getDuplicateRecipients = (state: State) => state.duplicateRecipients;

export const getEndDate = (state: State) => state.endDate;

export const getEndDateRestriction = (state: State) => state.endDateRestriction;

export const getEndTime = (state: State) => state.endTime;

export const getEndTimeEndRestriction = (state: State) => state.endTimeEndRestriction;

export const getEndTimeRestriction = (state: State) => state.endTimeRestriction;

export const getEngagementAllDaysBlocked = (state: State) => state.allDaysBlocked;

export const getEngagementCampaignName = (state: State) => state.campaignName;

export const getEngagementCount = (state: State) => state.count;

export const getEngagementCustomerTypes =  (state:State) => state.availableCustomerTypes;

export const getEngagementDefaultMessageForApp = (state: State) => state.defaultMessageForApp;

export const getEngagementDetailRequest = (state: State) => {
  return {
    campaignName: state.campaignName,
    count: state.count,
    emailMessage: state.message,
    engagementMode: state.engagementMode,
    engagementType: state.engagementType,
    flowId: state.flowId,
    selectedCustomers: state.selectedCustomers,
    subject: state.subject
  };
};

export const getEngagementEmail = (state: State) => state.email;

export const getEngagementError = (state: State) => state.error;

export const getEngagementFailedPlatforms =  (state:State) => state.failedPlatforms;

export const getEngagementFlowId = (state: State) => state.flowId;

export const getEngagementHtml = (state: State) => state.previewHtml;

export const getEngagementLoading = (state: State) => state.loading;

export const getEngagementMenuItems = (state: State) => state.menuItems;

export const getEngagementMessage = (state: State) => state.message;

export const getEngagementMode = (state: State) => state.engagementMode;

export const getEngagementOptInOptions = (state:State) => state.optInOptions;

export const getEngagementOrganizations = (state: State) => state.organizations;

export const getEngagementPlatforms =  (state:State) => state.platforms;

export const getEngagementPrimaryText = (state: State) => state.primaryText;

export const getEngagementPushMessageText = (state: State) => state.pushMessageText;

export const getEngagementPushMessageTitle = (state: State) => state.pushMessageTitle;

export const getEngagementRecipients = (state: State) => state.offerRecipients;

export const getEngagementRewards = (state: State) => state.treats;

export const getEngagementSelectedCustomers = (state: State) => state.selectedCustomers;

export const getEngagementSelectedOptIn = (state:State) => state.selectedOptIn;

export const getEngagementSubject = (state: State) => state.subject;

export const getEngagementSuccessfulPlatforms =  (state:State) => state.successfulPlatforms;

export const getEngagementThirdPartyPromoCreatedId =  (state:State) => state.thirdPartyPromoCreatedId;

export const getEngagementType = (state: State) => state.engagementType;

export const getIncludeActiveTreats = (state: State) => state.includeActiveTreats;

export const getMaxDaysAllowed = (state: State) => state.maxDaysAllowed;

export const getOverlappingEngagementType = (state: State) => state.overlappingEngagementType;

export const getRepeatThisPromotion = (state: State) => state.repeatOptions;

export const getSelectedDestinationId = (state: State) => state.selectedDestinationId;

export const getSelectedDestinationIdsForMultiSelect = (state: State) => state.selectedDestinationIdsForMultiSelect;

export const getSelectedRepeatPromotion = (state: State) => state.selectedRepeatId;

export const getSelectedReward = (state: State) => state.selectedReward;

export const getSelectedTimeOfDay = (state: State) => state.selectedTimeOfDayId;

export const getSendError = (state: State) => state.sendError;

export const getSendLoading = (state: State) => state.sendLoading;

export const getStartDate = (state: State) => state.startDate;

export const getStartTime = (state: State) => state.startTime;

export const getStartTimeEndRestriction = (state: State) => state.startTimeEndRestriction;

export const getStartTimeRestriction = (state: State) => state.startTimeRestriction;

export const getTimeOfDayOptions = (state: State) => state.timeOfDayOptions;

export const getTreatLength = (state: State) => state.treatLength;

