// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment =  {
  production: false,
  env: 'development',
  appName: 'Spotlight',
  uiRedirectUrl: 'https://dev.spotlight.cfahome.com/login/callback', // Development UI URL
  oktaClientId: '0oa1elkry0tMPlkK40h8',
  oktaAuthUrl: 'https://cfahome.oktapreview.com', // Development Okta Auth URL
  oktaAuthIssuer: 'https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7',
  endSessionRedirectUri: "https://dev.spotlight.cfahome.com/",
  spotlightApiUrl: 'https://spotlight.dxe.dev.crndev.chick-fil-a.com',
  urlsForAuthHeader: [
    'https://spotlight.dxe.dev.crndev.chick-fil-a.com',
  ],
  launchDarklySdkKey: 'sdk-25c5ca95-708d-4ee4-95aa-8bbeda0be4d2',
  launchDarklyClientKey: '64dd3ca2a99b9d138da58ad7'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
