import { DataTableConstants } from '../shared/data-table/data-table.constants';

export class ProfileConstants {
  public static get dayParts(): any {
    return {
      breakfast: {
        label: 'Breakfast',
        color: 'rgb(255, 199, 44)'
      },
      lunch: {
        label: 'Lunch',
        color: 'rgb(164, 52, 58)'
      },
      afternoon: {
        label: 'Afternoon',
        color: 'rgb(252, 246, 204)'
      },
      dinner: {
        label: 'Dinner',
        color: 'rgb(105, 97, 88)'
      },
      latenight: {
        label: 'Late Night',
        color: 'rgb(244, 107, 65)'
      }
    };
  }

  public static get nonRedeemedTreatsTableColumns(): any[] {
    return [{
      prop: 'offerType',
      title: 'Offer Type',
      type: 'offertype',
      displayIconProp: 'displaySnapIn'
    }, {
      prop: 'offerName',
      title: 'Reward Name'
    }, {
      prop: 'createdBy',
      title: 'Created By'
    }, {
      prop: 'endDate',
      title: 'End Date',
      type: 'Date'
    }];
  }

  public static get nonRedeemedTreatsTableMobileColumns(): any[] {
    return [{
      prop: 'offerName',
      title: 'Reward Name'
    }, {
      prop: 'createdBy',
      title: 'Created By'
    }, {
      prop: 'endDate',
      title: 'End Date',
      type: 'Date'
    }];
  }

  public static get cemCommentsTableColumns(): any[] {
    return [{
      prop: 'dateOfReport',
      title: 'Report Date',
      mobile: DataTableConstants.mobileTitle,
      type: 'dateOfReport',
      showTimezone: true
    }, {
      prop: 'commentDescription',
      title: 'Comment',
      type: 'indicateComments',
      mobile: DataTableConstants.mobileSubtitle
    }];
  }

  public static get caresCommentsTableColumns(): any[] {
    return [{
      prop: 'status',
      title: 'Contact Status',
      mobile: 'title',
      hide: true
    }, {
      prop: 'visitDateTime',
      title: 'Visit Date',
      type: 'DayDateTime'
    }, {
      prop: 'reportDateTime',
      title: 'Contact Date',
      type: 'DayDateTime'
    }, {
      prop: 'spotlightReportDate',
      title: 'Report Received',
      type: 'DayDateTime',
      mobile: 'subtitle',
      mobileTitle: 'Received: '
    }, {
      prop: 'priority',
      title: 'Priority',
      mobile: 'subtext'
    }, {
      prop: 'contactRequired',
      title: 'Contact Required'
    }, {
      prop: 'status',
      title: 'Contact Status'
    }, {
      prop: 'notes',
      title: 'Notes',
      type: 'viewNotes'
    }];
  }

  public static get digitalRefundsTableColumnsMobile(): any[] {
    return [{
      prop: 'transactionDate',
      title: 'Date',
    }, {
      prop: 'foodItems',
      title: 'Refund Item',
    }, {
      prop: 'refundIssues',
      title: 'Refund Issue(s)',
    }, {
      prop: 'total',
      title: 'Total',
    }];
  }

  public static get digitalRefundsTableColumns(): any[] {
    return [{
      prop: 'transactionDate',
      title: 'Transaction Date'
    }, {
      prop: 'dayPart',
      title: 'Day Part'
    }, {
      prop: 'destination',
      title: 'Destination'
    }, {
      prop: 'foodItems',
      title: 'Refund Item(s)'
    },{
      prop: 'itemQualityIssue',
      title: 'Item Quality Issue'
    },{
      prop: 'refundIssues',
      title: 'Refund Issue(s)'
    }, {
      prop: 'total',
      title: 'Total',
      type: 'currency'
    }];
  }

  public static get actionHistoryTableColumns(): any[] {
    return [{
      prop: 'updatedAt',
      title: 'Date',
      type: 'DayDateTime',
      size: 15,
      showTimezone: true
    }, {
      prop: 'updatedBy',
      title: 'User',
      type: 'defaultNA',
      size: 15,
      sort: false
    }, {
      prop: 'actionTaken',
      title: 'Action Taken',
      size: 40,
      sort: false
    }, {
      prop: 'hasEmailRecord',
      title: 'Communication Sent',
      type: 'conditionalExternalLink',
      text: 'View Email',
      size: 15,
      sort: false
    }, {
      prop: 'recoveryNotes',
      title: 'Notes',
      size: 15,
      type: 'viewNotes',
      sort: false
    }];
  }

  public static get recoveryIssuedColumn()
  {
    return {
      title: 'Recovery Issued',
      prop: 'recoveryIssued'
    }
  }

  public static get curbsideFlag(){
    return 'digitalRefundsCurbside'
  }
  public static get redeemedTreatsTableColumns(): any[] {
    return [{
      prop: 'offerType',
      title: 'Offer Type',
      type: 'offertype',
      displayIconProp: 'displaySnapIn'
    }, {
      prop: 'offerName',
      title: 'Reward Name'
    }, {
      prop: 'createdBy',
      title: 'Created By'
    }, {
      prop: 'redeemedLocationName',
      title: 'Redeemed Location'
    }, {
      prop: 'redeemedDate',
      title: 'Redeemed Date',
      type: 'Date'
    }];
  }

  public static get redeemedTreatsTableMobileColumns(): any[] {
    return [{
      prop: 'offerName',
      title: 'Reward Name'
    }, {
      prop: 'redeemedLocationName',
      title: 'Redeemed Location'
    }, {
      prop: 'redeemedDate',
      title: 'Redeemed Date',
      type: 'Date'
    }];
  }

  public static get transactionsTableColumns(): any[] {
    return [{
      prop: 'transactionDate',
      title: 'Transaction Date',
      type: 'UTCDate',
      size: "155px"
    }, {
      prop: 'transId',
      title: 'Order Number',
      size: "250px"
    }, {
      prop: 'transDayPart',
      title: 'Day Part'
    }, {
      prop: 'orderChannel',
      title: 'Order Channel'
    }, {
      prop: 'transDestination',
      title: 'Destination'
    }, {
      prop: 'discountPercent',
      title: 'Discount',
      type: 'roundedPercentage'
    },  {
      prop: 'forgot2Scan',
      title: 'Forgot2Scan',
      type: 'forgot2ScanIcon',
      centerContent: true
    }, {
      prop: 'totalTenderDollar',
      title: 'Total',
      type: 'currency'
    }];
  }

  public static get transactionDetailColumns(): any[] {
    return [{
      prop: 'menuName',
      title: 'Menu Item',
      type: 'html'
    }, {
      prop: 'quantity',
      title: 'Quantity'
    }, {
      prop: 'price',
      title: 'Price',
      type: 'currency'
    }, {
      prop: 'itemDiscount',
      title: 'Discount',
      type: 'currency'
    }, {
      prop: 'subTotal',
      title: 'Subtotal',
      type: 'currency'
    }];
  }

  public static get mobileTransactionsTableColumns(): any[] {
    return [{
      prop: 'transactionDate',
      title: 'Date',
      type: 'UTCDate',
      size: 40
    }, {
      prop: 'transId',
      title: 'Order No.',
      size: 20
    }, {
      prop: 'transDayPart',
      title: 'Day Part',
      size: 20
    }, {
      prop: 'transDestination',
      title: 'Dest.',
      size: 20
    }, {
      prop: 'discountPercent',
      title: 'Disc.',
      type: 'roundedPercentage',
      size: 10
    }, {
      prop: 'totalTenderDollar',
      title: 'Total',
      type: 'currency',
      size: 10
    }];
  }

  public static get mobileTransactionDetailColumns(): any[] {
    return [{
      prop: 'menuName',
      title: 'Menu Item',
      type: 'html',
      size: 30
    }, {
      prop: 'quantity',
      title: 'Qty',
      size: 10
    }, {
      prop: 'price',
      title: 'Price',
      type: 'currency',
      size: 20
    }, {
      prop: 'itemDiscount',
      title: 'Disc.',
      type: 'currency',
      size: 20
    }, {
      prop: 'subTotal',
      title: 'Subtotal',
      type: 'currency',
      size: 20
    }];
  }

  public static get noCEMResultsMessage(): any {
    return 'This customer has not submitted CEM feedback while logged into their Chick-fil-A'
      + ' One account via web or mobile forms.';
  }

  public static get noCaresResultsMessage(): any {
    return 'This customer has not submitted CARES data while logged into their Chick-fil-A'
      + ' One account via web or mobile forms.';
  }

  public static get actionHistoryNoResultsMessage(): any {
    return 'No restaurant contact found';
  }

  public static get postTitle(): any {
    return '(Limited to most recent 250 transactions)';
  }

  public static get customerResponsePostTitleWithTz(): any {
    return ' for the last 90 days. Displayed times use your Restaurant timezone.';
  }

  public static get cemData(): string {
    return 'cemData';
  }

  public static get caresData(): string {
    return 'caresData';
  }

  public static get digitalRefundsData(): string {
    return 'digitalRefundsData';
  }

  public static get actionHistory(): string {
    return 'actionHistory';
  }

  public static get cateringData(): string {
    return 'cateringTransactionHistory';
  }

  public static get transactionStats(): string {
    return 'transactionStats';
  }

  public static get transactionHistory(): string {
    return 'transactionHistory';
  }

  public static get offers(): string {
    return 'customerOffers';
  }

  public static get recoverEmail(): string {
    return 'recoverEmail';
  }

  public static get cemType(): string {
    return 'cem';
  }

  public static get customerInfo(): string {
    return 'customerInformation';
  }

  public static get defaultPageSize(): number {
    return 20;
  }

  public static get NoteNoResultsMessage(): string {
    return 'No customer notes found.';
  }

  public static get NoteErrorMessage(): string {
    return 'There was an error loading notes. Please try again later.';
  }

  public static get DefaultTransactionDateSort(): any {
    return { prop: 'transactionDate', order: 'asc' };
  }

  public static get deleteTitle() {
    return 'Would you like to delete this note?';
  }

  public static get deleteText() {
    return 'This action is permanent and you will not be able to recover data.';
  }

  public static get deleteIcon() {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/Delete_RedIcon.png';
  }

  public static get confirmDeleteText() {
    return 'Delete';
  }

  public static get cancelDeleteText() {
    return 'No, thanks';
  }

  public static get dialogText() {
    return 'Are you sure you want to delete this note?';
  }

  public static get defaultCateringTransactionDays(): number {
    return 365;
  }

  public static get defaultAllTransactionDays(): number {
    return 90;
  }

  public static get defaultCareContactsDays(): number {
    return 90;
  }

  public static get defaultNonRedeemedTreatsDateSort(): any {
    return { prop: 'endDate', order: 'asc' };
  }

  public static get defaultRedeemedTreatsDateSort(): any {
    return { prop: 'redeemedDate', order: 'asc' };
  }

  public static get defaultExpiredRewardDateSort(): any {
    return { prop: 'endDate', order: 'asc' };
  }

  public static get doorDashText(): string {
    return 'Delivery Fees and Service Fees are not displayed for 3rd Party delivery orders.';
  }

  public static get unsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get unsavedChangesText(): string {
    return 'Closing this note will discard any changes you\'ve made. Are you sure you want to continue?';
  }

  public static get unsavedChangesIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/UnsavedChanges_Icon.png';
  }

  public static get unsavedChangesConfirmButton(): string {
    return 'Go Back';
  }

  public static get unsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get doorDashPostTitle(): any {
    return ' By DoorDash';
  }

  public static get doorDashTableColumns(): any[] {
    return [{
      prop: 'transactionDate',
      title: 'Transaction Date',
      type: 'DayDateTime',
      size: 15,
      showTimezone: true
    }, {
      prop: 'reportDate',
      title: 'Report Date',
      type: 'DayDateTime',
      size: 15,
      showTimezone: true
    }, {
      prop: 'transId',
      title: 'Order Number',
      type: 'orderNumber',
      size: 12,
      sort: false,
      isSelectableAttrName:'isSelectable'
    }, {
      prop: 'refundAmount',
      title: 'Refund Amount',
      type: 'currency',
      size: 10
    }, {
      prop: 'refundReasons',
      title: 'Refund Reason',
      type: 'refundReasons',
      size: 32
    }, {
      prop: 'refundedAt',
      title: 'Refunded At',
      type: 'DayDateTime',
      size: 15,
      showTimezone: true
    }];
  }

  public static get noDoorDashResultsMessage(): any {
    return 'The customer has not reported any issues to DoorDash Customer Service.';
  }

  public static get doorDashRecoveryData(): string {
    return 'doorDashRecoveryData';
  }

  public static get doorDashRecoveryInfoText(): string {
    return '<span>Customers who order 3rd Party Delivery are directed to report any issues to DoorDash Customer Service ' +
    'directly via the DoorDash app.</br>Order Numbers may not be visible if we are unable to pull the information from ' +
    "DoorDash's recovery data, and will only be selectable if the transaction falls within the selected date range below."
  }

  public static get idAttributeForTransactionDetails(): string {
    return 'transId';
  }

  public static get doorDashRecovery(): string {
    return 'doorDashRecovery';
  }

  public static get digitalRefundsFlag(): string {
    return 'digitalRefund';
  }

  public static get doorDashRecoveryHistoryPageSize(): number {
    return 5;
  }

  public static get caresNotesProp(): string {
    return 'notes';
  }

  public static get restaurantRecoveryNoteProp(): string {
    return 'recoveryNotes';
  }
}
